import logo from "./logo.svg";
import css from "./components/main/style.module.css";
import FirstBlock from "./components/main/firstBlock";

import Cinnosti from "./components/main/cinnosti";
import Servicec from "./components/main/servicec";
import GoogleMapComponent from "./components/main/googleMapComponents";
import Address from "./components/main/address";

import ContactForm from "./components/main/contactForm";
import Footer from "./components/main/footer";
import Chat from "./components/chat/chat";

import { I18nextProvider, useTranslation } from "react-i18next";
import BlockNew from "./components/main/blockNew";
import ChomyVagluvo from "./components/main/chomyVagluvo";
import EtapuReg from "./components/main/etapuReg";
import LastReg from "./components/main/lastReg";
import BlockRozetcaFirst from "./components/main/blockRozetcaFirst";
import Popup from "./components/main/popup";
import { useState } from "react";
import OurWorks from "./components/main/ourWorks";
import PopupNew from "./components/main/popupNew";
import WhyWeAre from "./components/main/whyWeAre";

function App() {
  const { t, i18n } = useTranslation();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isEnaoPop, setIsEnaoPop] = useState(false);
  const [trueConntact, setTrueConntact] = useState(false);
  const openPopup = () => setIsPopupOpen(true);
  const closePopup = () => setIsPopupOpen(false);
  const openPopupNew = () => setIsEnaoPop(true);
  const closePopupNew = () => setIsEnaoPop(false);
  return (
    <>
      {" "}
      <I18nextProvider i18n={i18n}>
        {" "}
        <FirstBlock />
        <div className={css.wrapWithoutP} id="section3">
          <Cinnosti />
          <WhyWeAre />
          <BlockNew />
          <div className={css.popUpButton} onClick={openPopup}>
            {t("connect")}
          </div>
          <ChomyVagluvo />

          <EtapuReg trueConntact={trueConntact} openPopup={openPopup} />

          <LastReg />
          <OurWorks />
          <BlockRozetcaFirst openPopup={openPopup} />
          <div className={css.popUpButton} onClick={openPopup}>
            {t("connect")}
          </div>
          <div className={css.proslcS}></div>
          <Servicec />
          <ContactForm />
          <Address />
          {isPopupOpen && <Popup onClose={closePopup} t={t} />}
          {isEnaoPop && (
            <PopupNew
              setTrueConntact={setTrueConntact}
              onClose={closePopupNew}
              t={t}
            />
          )}
          {/* <Chat /> */}
          <Footer />
        </div>{" "}
      </I18nextProvider>
    </>
  );
}

export default App;
